<template>
  <div>
    <v-card :loading="loading">
      <v-card-title>
        {{ $t("collect-due") }}
        <v-spacer></v-spacer>
        <back-button></back-button>
      </v-card-title>
      <v-card-subtitle class="flex-column d-flex">
        <b>{{ $t("sales-rep") }}. {{ rep.fullName }}</b>
        <b>{{ $t("name") }}: {{ obj.fullName }}</b>
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          disable-pagination
          :headers="test"
          :items="dues"
          :loading="loading"
          hide-default-footer
          :no-data-text="$t('no-data')"
          class="mt-2"
          hide-actions

        >
          <template v-slot:item.created="{ item }">
            <span dir="ltr">
              {{ $service.formatDate(new Date(item.created), false) }}
            </span>
          </template>
          <template v-slot:item.dateReceipt="{ item }">
            <span dir="ltr">
              {{ $service.formatDate(new Date(item.created), false) }}
            </span>
          </template>
          <template v-slot:item.id="{ index }">
            <span dir="ltr">
              {{ index + 1 }}
            </span>
          </template>
          <template v-slot:item.zeroing="{ item }">
            <v-btn
              color="error"
              v-if="!item.zeroing"
              @click="openConfirmDialog(item)"
              >{{ $t("zeroing") }}</v-btn
            >
            <v-btn color="success" icon large v-else>
              <v-icon>mdi-check-all</v-icon>
              {{ $t("zeroed") }}
            </v-btn>
          </template>
        </v-data-table>
        <!-- <v-pagination
          v-model="options.PageIndex"
          :length="parseInt(total / options.PageSize) + 1"
        /> -->
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="confirmDialog" width="600">
      <v-card :loading="loading" outlined>
        <v-card-title class="my-5">
          {{ $t("confirm-zeroing") }}
          <v-spacer></v-spacer>
          <v-btn @click="confirmDialog = !confirmDialog" text color="error">
            <f-icon icon="times-circle" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ $t("are-you-sure-you-want-to-zeroing") }}
        </v-card-text>
        <v-card-actions class="my-5">
          <v-spacer></v-spacer>
          <v-btn @click="confirmDialog = !confirmDialog" outlined color="error">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn @click="zeroing(selectedItem)" color="success">
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BackButton from "../../../../components/BackButton.vue";
export default {
  components: { BackButton },
  computed: {
    type() {
      return this.$route.params.type;
    },
    rep() {
      return this.$route.params.rep != undefined
        ? this.$route.params.rep
        : { fullName: "" };
    },
    obj() {
      return this.$route.params.obj != undefined
        ? this.$route.params.obj
        : { fullName: "" };
    },
  },
  data() {
    return {
      loading: false,
      dues: [],
      headers: [
        {
          text: this.$t("list-no"),
          value: "id",
        },
        {
          text: this.$t("receipt"),
          value: "receiptAmount",
        },
        {
          text: this.$t("receipt-date"),
          value: "dateReceipt",
        },
        {
          text: this.$t("zeroing"),
          value: "zeroing",
        },
        {
          text: this.$t("created"),
          value: "created",
        },
      ],
      selectedItem: null,
      confirmDialog: false,
    };
  },
  methods: {
    openConfirmDialog(item) {
      this.selectedItem = item;
      this.confirmDialog = true;
    },
    zeroing(item) {
      this.loading = true;
      this.$http
        .delete(`/${this.type}/zeroing/${item.id}`)
        .then(() => {
          this.getDues();

          this.confirmDialog = false;
        })
        .catch((e) => {
          this.confirmDialog = false;
          this.loading = false;
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    getDues() {
      this.loading = true;
      let type = this.type.split(/(?=[A-Z])/)[0].toLowerCase();
      let params = {
        receivingUserId: this.rep.id,
      };
      params[type + "Id"] = this.obj.id;
      this.$http
        .get(`/${this.type}/collectDues`, {
          params: params,
        })
        .then((res) => {
          this.dues = res.data.result;
          this.loading = false;
        });
    },
  },
  created() {
    //
    if (
      this.$route.params.rep == null ||
      this.$route.params.obj == null ||
      this.$route.params.rep == undefined ||
      this.$route.params.obj == undefined
    ) {
      this.$router.push({ name: "accounts-wallet" });
    }
    this.getDues();
  },
};
</script>

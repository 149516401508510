var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("collect-due"))+" "),_c('v-spacer'),_c('back-button')],1),_c('v-card-subtitle',{staticClass:"flex-column d-flex"},[_c('b',[_vm._v(_vm._s(_vm.$t("sales-rep"))+". "+_vm._s(_vm.rep.fullName))]),_c('b',[_vm._v(_vm._s(_vm.$t("name"))+": "+_vm._s(_vm.obj.fullName))])]),_c('v-card-text',[_c('v-data-table',{staticClass:"mt-2",attrs:{"disable-pagination":"","headers":_vm.test,"items":_vm.dues,"loading":_vm.loading,"hide-default-footer":"","no-data-text":_vm.$t('no-data'),"hide-actions":""},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created), false))+" ")])]}},{key:"item.dateReceipt",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created), false))+" ")])]}},{key:"item.id",fn:function(ref){
var index = ref.index;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(index + 1)+" ")])]}},{key:"item.zeroing",fn:function(ref){
var item = ref.item;
return [(!item.zeroing)?_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.openConfirmDialog(item)}}},[_vm._v(_vm._s(_vm.$t("zeroing")))]):_c('v-btn',{attrs:{"color":"success","icon":"","large":""}},[_c('v-icon',[_vm._v("mdi-check-all")]),_vm._v(" "+_vm._s(_vm.$t("zeroed"))+" ")],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',{attrs:{"loading":_vm.loading,"outlined":""}},[_c('v-card-title',{staticClass:"my-5"},[_vm._v(" "+_vm._s(_vm.$t("confirm-zeroing"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.confirmDialog = !_vm.confirmDialog}}},[_c('f-icon',{attrs:{"icon":"times-circle"}})],1)],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("are-you-sure-you-want-to-zeroing"))+" ")]),_c('v-card-actions',{staticClass:"my-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){_vm.confirmDialog = !_vm.confirmDialog}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.zeroing(_vm.selectedItem)}}},[_vm._v(" "+_vm._s(_vm.$t("confirm"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }